<template>
  <q-layout view="hHh LpR fFf" class="bg-light-grey">
    <div class="bg-image" />
    <q-page-container>
      <q-page>
        <div class="row absolute-full items-center">
          <div class="col-lg-2" />
          <div
            id="loginBox"
            class="login full-height q-pa-lg text-center"
            :class="{'desktop': $q.screen.gt.md}"
          >
            <img
              :src="require('assets/mobilleo-logo.svg')"
              class="logo q-mb-md cursor-pointer"
              @click="$router.push({ path: '/' })"
            >
            <router-view />
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: 'LayoutLogin',
  activated () {
    const resize = () => {
      const el = document.getElementById('loginBox')

      if (!el.classList.contains('desktop')) {
        el.style.right = null
        el.style.paddingLeft = null
        el.style.paddingRight = null
        return
      }
      let offset = Math.max(0, (window.outerWidth / 2) - (window.outerWidth - window.innerWidth))
      el.style.right = `${offset}px`

      el.style.paddingLeft = `${(window.outerWidth / 12)}px`
      el.style.paddingRight = `${(window.outerWidth / 6)}px`
    }
    window.onresize = resize
    resize()
  }
}
</script>

<style lang="stylus" scoped>
html {
  background-color: #3face1;
}

@keyframes carousel {
  0%, 2.5% {
    background-image url('~assets/login/Airport.png')
  }
  10%, 12.5%, 15% {
    background-image url('~assets/login/Bike.png')
  }
  22.5%, 25%, 27.5% {
    background-image url('~assets/login/Bus.png')
  }
  35%, 37.5%, 40% {
    background-image url('~assets/login/BusStop.png')
  }
  47.5%, 50%, 52.5% {
    background-image url('~assets/login/Scooter.png')
  }
  60%, 62.5%, 65% {
    background-image url('~assets/login/Ticket.png')
  }
  72.5%, 75%, 77.5% {
    background-image url('~assets/login/Tram.png')
  }
  85%, 87.5%, 90% {
    background-image url('~assets/login/Tube.png')
  }
  97.5%, 100% {
    background-image url('~assets/login/Airport.png')
  }
}

.q-layout
  @media (min-width 768px)
    overflow-y scroll

.bg-image
  &:before,
  &:after
    content ''
    position fixed
    top 0
    bottom 0
    left 0
    right 0
    z-index 0
  &:before
    background-image url('~assets/Mobilleo Wave.svg')
    background-position left top
    background-repeat no-repeat
    background-size auto 100%
  @media (min-width 768px)
    width 100vw
    height 100vh
    position fixed
    background-repeat no-repeat
    background-position center center
    background-size cover
    animation carousel 48s infinite ease-out
    &:after
      // This pre-loads the images so they dont flicker
      height 0
      width 0
      z-index -1
      position absolute
      overflow hidden
      content url('~assets/login/Bike.png') url('~assets/login/Bus.png') url('~assets/login/BusStop.png') url('~assets/login/Scooter.png') url('~assets/login/Ticket.png') url('~assets/login/Tram.png') url('~assets/login/Tube.png')

.q-page
  z-index 10

.login-layout {
  min-width: 425px;

  >>> .q-btn.text-white {
    color: #fff !important;
  }
}

.desktop
  position absolute
  top 50%
  left 0
  right 0
  transform translateY(-25%)

.logo {
  width: 70%;
  max-width: 300px;
}
</style>
