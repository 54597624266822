<template>
  <q-layout view="hHh LpR fFf" class="bg-light-grey">
    <q-page-container>
      <q-page>
        <div class="row absolute-full items-center">
          <div class="col-lg-2" />
          <div
            class="col col-md-4 col-lg-3 full-height q-pa-lg text-center"
            style="background-color: white; color: #9b1889"
          >
            <img
              :src="require('assets/GO-Hi rgb logo.svg')"
              class="logo q-mb-md cursor-pointer"
              @click="$router.push({ path: '/' })"
            >
            <router-view />
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: 'LayoutLogin'
}
</script>

<style lang="stylus" scoped>
html {
  background-color: white;
}

.q-page {
  background-image: url('~assets/gohi-login-bg.jpg');
  background-size: cover;
  background-position: top;
}

.login-layout {
  min-width: 425px;

  >>> .q-btn.text-white {
    color: #fff !important;
  }
}

.logo {
  width: 45%;
  max-width: 300px;
}
</style>
